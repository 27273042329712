<template>
    <el-container>
        <el-container>
            <!-- 中间内容 -->
            <el-main>
                <h2 class="depTitle">{{listTit}}</h2>
                <div class="hosrow">
                    <div></div>
                    <div>
                        <el-button type="primary" @click="depaddKeshi">新增</el-button>
                    </div>
                </div>
                <el-table :data="tableData" border center>
                    <el-table-column type="index" label="序号" width="55" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="指标名称" align="center">
                    </el-table-column>
                    <el-table-column prop="connotation" label="指标内涵" align="center">
                    </el-table-column>
                    <el-table-column prop="status" label="指标状态" align="center" width="100">
                        <template slot-scope="scope">
                            <!-- 指标状态（0启用 1禁用） -->
                            <div v-if="scope.row.status=='0'">
                                <el-switch v-model="value" active-color="#13ce66" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>

                            <div v-else-if="scope.row.status=='1'">
                                <el-switch v-model="novalue" active-color="#13ce66" inactive-color="#929292" disabled>
                                </el-switch>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column prop="drivefactor" label="指标驱动系数" align="center">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center" width="200px">
                        <template slot-scope="scope">
                            <div class="remakSty">{{scope.row.remark}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="130">
                        <template style="display: flex" slot-scope="scope">
                            <el-button size="mini" type="primary" @click="editKeshi(scope.row)"><i class="el-icon-edit"></i></el-button>
                            <el-button size="mini" type="danger" @click="deleteKeshi(scope.row)"><i class="el-icon-delete-solid"></i></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background @current-change="handleCurrentChange" :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 点击新增人员信息弹出框 -->
        <el-dialog :title="diaTit" :visible.sync="AdddialogVisible" width="700px" :before-close="AdddialogVisibleClose">
            <add-in-man :diaBtn='diaBtn' :listTit='listTit' :wardId='wardId' ref='addinman' :EditDateModel='AddDateModel' @listworkIn='listworkIn' />
        </el-dialog>
    </el-container>
</template>

<script>
import _qs from "qs";
import addInMan from "../AddInMan/addInMan.vue";
export default {
    data() {
        return {
            listTit: "工作量指标管理",
            value: true,
            novalue: false,
            diaBtn: "新增",
            diaTit: "新增",
            // 点击添加科室弹出框
            AdddialogVisible: false,
            AddDateModel: {},
            // 当前页数
            pageNum: 1,
            // 每页显示条数
            pageSize: 10,
            // 总数
            total: 1,
            // 表格数据
            tableData: [],
            // 科室id
            departmentId: window.sessionStorage.getItem("departmentId"),
            wardId: "", //病区id
        };
    },
    components: {
        addInMan,
    },
    created() {
        this.listworkIn();
    },
    methods: {
        // 科室管理分页
        handleCurrentChange(val) {
            this.pageNum = val;
            // console.log(`当前页: ${val}`);
            this.listworkIn();
        },
        // 点击添加科室
        depaddKeshi() {
            this.AdddialogVisible = true;
        },
        // 点击添加科室的时候取消正则
        AdddialogVisibleClose() {
            this.AdddialogVisible = false;
            this.$refs.addinman.clearadd();
            this.diaTit = "新增";
            this.diaBtn = "新增";
        },

        // 点击操作删除
        async deleteKeshi(row) {
            const confirmResult = await this.$confirm("确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).catch((err) => err);

            if (confirmResult !== "confirm") {
                return this.$message.info("已经取消删除");
            }
            let data = _qs.stringify({
                id: row.id,
            });
            let res = await this.$axios.deleteworkIn(data);
            // console.log(res);
            if (res.data.code == 401) {
                this.$router.push("/login");
            } else if (res.data.code == 200) {
                this.listworkIn();
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
        // 点击操作修改科室的添加
        editKeshi(row) {
            // console.log(row)
            this.wardId = row.id;
            this.diaBtn = "修改";
            this.diaTit = "修改工作量指标管理";
            this.AdddialogVisible = true;
            this.AddDateModel = row;
        },
        // 分页显示病区列表数据
        async listworkIn() {
            this.AdddialogVisible = false;
            this.diaBtn = "新增";
            this.diaTit = "新增";
            let data = {
                pageNum: this.pageNum, //页数
                pageSize: this.pageSize, //每页显示条数
                deleteFlag: "0", //数据状态（0正常 1已删除）
                name: "", //指标名称
                status: "", //指标状态（0启用 1禁用）
            };
            let { data: res } = await this.$axios.listworkIn(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 200) {
                this.tableData = res.rows;
                this.total = res.total;
            } else {
                this.$message({
                    message: res.data.msg,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.RightDiv {
    margin: 0 auto;
}

.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 20px;
}
.depart-addnum {
    width: 100%;
}

.AddUser {
    float: right;
    margin-bottom: 10px;
}

.AddUserDiv {
    width: 95%;
    margin: 0 auto;
}

.depar-dia {
    width: 100%;
}
.collapse-btn {
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: rgb(134 140 146);
}
/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .el-dialog__body {
    text-align: inherit;
}

.el-main {
    .table-title {
        display: flex;
        margin-bottom: 10px;
        .index-add {
            margin-left: 20px;
        }
    }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.hosrow {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.activeStatus {
    background: rgb(102, 204, 0);
}

.noStatus {
    background: #ccc;
}

.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

/deep/ .el-input .el-input__inner {
    width: 300px;
}
</style>
